@import "@/assets/constants/index.scss";
.filters-place {
  .filter-name {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.1px;
    color: $c-primary;
  }

  .reset-filters-link {
    width: 100%;
    height: 24px;
    color: $c-blue-grey1;
    font-size: 12px;
    text-align: right;
    padding: 4px 20px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}
