@import "@/assets/constants/index.scss";
.form-parent.drawer-content {
  &.parent-full-size {
    width: auto;
  }

  .score-name {
    width: 120px;
  }

  .score-value {
    width: 104px;
    margin-left: 16px;
  }

  .age-value {
    width: 136px;

    &.last-value {
      margin-left: 16px;
    }
  }
  .scope-name-parent {
    width: 192px;
    height: 40px;
    border: 2px dotted $c-blue-grey3;
    border-radius: 8px;
    padding: 0px 8px 0px 16px;

    span {
      color: $c-grey2;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
    }
  }

  .el-select.operator-select {
    .select-trigger {
      .el-input {
        width: 136px;
        border-radius: 8px;
        border: 1px solid $c-blue-grey2;
        color: $c-grey2;

        .el-input__inner {
          background-color: transparent;
        }
      }
    }
  }
}

.drawer-header {
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    color: $c-grey2;
    margin-left: 8px;
  }
}

.el-drawer a.reset-filters {
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  color: $c-blue-grey1;

  &.disabled {
    cursor: not-allowed;
  }
}

.form-parent.full-width .el-form-item {
  width: auto;
}
